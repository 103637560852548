import React from "react";
import { NavLink } from "react-router-dom";

function ActiveNavButton({ to, label }) {
  return (
    <NavLink
      className={({ isActive }) =>
        isActive
          ? "bg-green-500 text-white text-center px-4 py-2 rounded-lg hidden md:block shadow-lg"
          : "bg-slate-300 text-black px-4 py-2 text-center rounded-lg hidden md:block shadow-lg"
      }
      to={to}
    >
      {label}
    </NavLink>
  );
}

export default ActiveNavButton;
