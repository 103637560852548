import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutUser } from "../../../redux/actions/auth.action";

function Header() {
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const signOutHandler = () => {
    dispatch(signOutUser());
  };
  return (
    <div className='flex justify-between w-full bg-slate-500 text-white items-center p-4'>
      <p>{`Welcome back ${user.firstName}`}</p>
      <p className='hidden md:flex'>Barmans Beer Club</p>
      <button
        className='bg-blue-400 px-2 py-2 rounded-lg shadow-lg text-white'
        onClick={() => signOutHandler()}
      >
        Sign Out
      </button>
    </div>
  );
}

export default Header;
