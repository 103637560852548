import React from "react";

function CloseIcon({ color }) {
  return (
    <svg viewBox='0 0 100 80' width='20' height='50'>
      <line x1='10' y1='10' x2='100' y2='100' stroke={color} strokeWidth='10' />
      <line x1='100' y1='10' x2='10' y2='100' stroke={color} strokeWidth='10' />
    </svg>
  );
}

export default CloseIcon;
