import React from "react";

function SelectField({ label, value, onChange, options }) {
  return (
    <div className='flex flex-col mt-2 mb-4'>
      <label className='mb-2'>{label}</label>
      <select
        className='p-2 h-10 border rounded-lg'
        onChange={onChange}
        value={value}
      >
        <option disabled value=''>
          Please Select Option...
        </option>
        {options.map((option) => (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SelectField;
