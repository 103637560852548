import React from "react";

import SideBar from "./components/SideBar";
import Header from "./components/Header";

function Layout({ children, loading }) {
  return (
    <div className='flex flex-col min-h-screen bg-slate-500 text-white'>
      <Header />
      <div className='flex grow'>
        <SideBar />
        <div className='flex flex-col grow text-black bg-slate-200'>
          <div className='p-2'>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
