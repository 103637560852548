import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/layout";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { firebaseAuth } from "../../firebase";
import { formattedDate } from "../../utils/dates.util";

function SubscriptionsScreen() {
  const [loading, setLoading] = useState(false);
  const [subscribedUsers, setSubscribedUsers] = useState(null);

  useEffect(() => {
    if (subscribedUsers === null) {
      setLoading(true);
      onAuthStateChanged(firebaseAuth, async (user) => {
        if (user) {
          const userToken = user.accessToken;
          try {
            const { data } = await axios.get(
              `${process.env.REACT_APP_BACKEND}/admin/subscribed`,
              {
                headers: {
                  Authorization: "Bearer " + userToken,
                },
              }
            );
            setSubscribedUsers(data);
            setLoading(false);
          } catch (error) {
            console.log(error);
            toast.error("Error retrieving subscribed users");
          }
        }
      });
    }
  }, [subscribedUsers]);
  return (
    <Layout>
      <div className='flex flex-col mt-8'>
        <table className='table-auto border w-full mx-auto'>
          <thead>
            <tr className='bg-slate-700 text-white'>
              <th className='text-left border px-2 py-1'>Name</th>
              <th className='text-center border px-2 py-1'>Package</th>
              <th className='text-center border px-2 py-1'>Active</th>
              <th className='text-center border px-2 py-1'>Shipments Due</th>
              <th className='text-center border px-2 py-1'>Next Shipment</th>
              <th className='text-center border px-2 py-1'>Next Payment</th>
            </tr>
          </thead>
          <tbody>
            {subscribedUsers &&
              subscribedUsers.map((row, index) => (
                <tr
                  key={row.id}
                  className={index % 2 ? "bg-slate-100 " : "bg-slate-300"}
                >
                  <td className='text-left border px-2 py-1'>
                    {`${row.firstName} ${row.lastName}`}
                  </td>
                  <td className='text-center border px-2 py-1'>
                    {row.subscriptionDetails.packageDetails.packageName}
                  </td>
                  <td className='text-center border px-2 py-1'>
                    {row.subscriptionDetails.active ? "Yes" : "No"}
                  </td>
                  <td className='text-center border px-2 py-1'>
                    {row.subscriptionDetails.shipmentsDue}
                  </td>
                  <td className='text-center border px-2 py-1'>
                    {formattedDate(row.subscriptionDetails.nextShipment)}
                  </td>
                  <td className='text-center border px-2 py-1'>
                    {formattedDate(row.subscriptionDetails.nextPayment)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {loading && (
          <div className='flex flex-col mt-8 w-full justify-center items-center'>
            <LoadingSpinner />
            <p>Gathering Data...</p>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default SubscriptionsScreen;
