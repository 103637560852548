import React from "react";

function DashboardCard({ label, data }) {
  return (
    <div className='flex flex-col text-center shadow-lg rounded-lg px-4 py-4 bg-white'>
      <p>{label}</p>
      <p className='text-2xl'>{data}</p>
    </div>
  );
}

export default DashboardCard;
