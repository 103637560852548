import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/ui/Button";
import ModalContainer from "../../../components/ui/ModalContainer";
import TextField from "../../../components/ui/TextField";
import { firebaseAuth } from "../../../firebase";

function AddBeerModal({ setAddNewBeer }) {
  const [loading, setLoading] = useState(false);
  const [newBeer, setNewBeer] = useState({
    brewery: "",
    beerName: "",
    beerAbv: 0,
    beerCost: 0,
    beerStock: 0,
  });

  const onCloseHandler = () => {
    setAddNewBeer(false);
  };

  const onSaveHandler = () => {
    setLoading(true);
    onAuthStateChanged(firebaseAuth, async (user) => {
      if (user) {
        const userToken = user.accessToken;

        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_BACKEND}/admin/beer`,
            {
              newBeer,
            },
            {
              headers: {
                Authorization: "Bearer " + userToken,
              },
            }
          );
          if (data.id) {
            toast.success("Beer Added");
            setAddNewBeer(false);
          } else {
            toast.error("Error saving beer");
            setLoading(false);
          }
        } catch (error) {
          toast.error("Error saving beer");
          setLoading(false);
        }
      }
    });
  };

  return (
    <ModalContainer title='Add New Beer' onClose={() => onCloseHandler()}>
      <div className='flex space-x-4'>
        <div>
          <TextField
            label='Brewery'
            value={newBeer.brewery}
            onChange={(e) =>
              setNewBeer({ ...newBeer, brewery: e.target.value })
            }
          />

          <TextField
            label='Beer ABV'
            value={newBeer.beerAbv}
            type='number'
            onChange={(e) =>
              setNewBeer({ ...newBeer, beerAbv: e.target.value })
            }
          />

          <TextField
            label='Beer Stock'
            value={newBeer.beerStock}
            type='number'
            onChange={(e) =>
              setNewBeer({ ...newBeer, beerStock: e.target.value })
            }
          />
        </div>
        <div>
          <TextField
            label='Beer Name'
            value={newBeer.beerName}
            onChange={(e) =>
              setNewBeer({ ...newBeer, beerName: e.target.value })
            }
          />
          <TextField
            label='Beer Cost'
            value={newBeer.beerCost}
            type='number'
            onChange={(e) =>
              setNewBeer({ ...newBeer, beerCost: e.target.value })
            }
          />
        </div>
      </div>
      <Button label='Save' onClick={() => onSaveHandler()} disabled={loading} />
    </ModalContainer>
  );
}

export default AddBeerModal;
