import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/layout";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { firebaseAuth } from "../../firebase";
import DashboardCard from "./components/DashboardCard";

function DashboardScreen() {
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    if (dashboardData === null) {
      setLoading(true);
      onAuthStateChanged(firebaseAuth, async (user) => {
        if (user) {
          const userToken = user.accessToken;
          try {
            const { data } = await axios.get(
              `${process.env.REACT_APP_BACKEND}/admin/data`,
              {
                headers: {
                  Authorization: "Bearer " + userToken,
                },
              }
            );
            setDashboardData(data);
            setLoading(false);
          } catch (error) {
            console.log(error);
            toast.error("Error retreiving dashboard data");
          }
        }
      });
    }
  }, [dashboardData]);
  return (
    <Layout>
      {dashboardData && (
        <div className='flex flex-wrap mt-10 justify-around px-8'>
          <DashboardCard
            label='Total Subscribers'
            data={dashboardData.subscriptionCount}
          />
          <DashboardCard
            label='Shipments Due'
            data={dashboardData.shipmentsDue}
          />
        </div>
      )}
      {loading && (
        <div className='flex flex-col mt-8 w-full justify-center items-center'>
          <LoadingSpinner />
          <p>Gathering Data...</p>
        </div>
      )}
    </Layout>
  );
}

export default DashboardScreen;
