import React, { useState } from "react";
import ModalContainer from "../../../components/ui/ModalContainer";
import TextField from "../../../components/ui/TextField";
import SelectField from "../../../components/ui/SelectField";
import Button from "../../../components/ui/Button";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "../../../firebase";
import axios from "axios";
import { toast } from "react-toastify";

function NewPackageModal({ setShowNewPackage }) {
  const [loading, setLoading] = useState(false);
  const [newPackage, setNewPackage] = useState({
    packageName: "",
    packageFrequency: "",
    beersIncluded: 4,
    packagePrice: 0,
    stripePriceId: "",
    packageDescription: "",
  });

  const onCloseHandler = () => {
    setShowNewPackage(false);
  };

  const onSaveHandler = () => {
    setLoading(true);
    onAuthStateChanged(firebaseAuth, async (user) => {
      if (user) {
        const userToken = user.accessToken;

        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_BACKEND}/admin/package`,
            {
              newPackage,
            },
            {
              headers: {
                Authorization: "Bearer " + userToken,
              },
            }
          );
          if (data.id) {
            toast.success("Package Added");
            setShowNewPackage(false);
          } else {
            toast.error("Error saving package");
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          toast.error("Error saving package");
          setLoading(false);
        }
      }
    });
  };

  const frequencyOptions = [
    {
      label: "Fortnightly",
      value: "2Weeks",
    },
    {
      label: "Monthly",
      value: "1Month",
    },
  ];
  return (
    <ModalContainer title='Create New Package' onClose={() => onCloseHandler()}>
      <div className='flex space-x-4'>
        <div>
          <TextField
            label='Package Name'
            value={newPackage.packageName}
            onChange={(e) =>
              setNewPackage({ ...newPackage, packageName: e.target.value })
            }
          />
          <SelectField
            label='Delivery Frequency'
            options={frequencyOptions}
            value={newPackage.packageFrequency}
            onChange={(e) =>
              setNewPackage({ ...newPackage, packageFrequency: e.target.value })
            }
          />
          <TextField
            label='Beers Included'
            type='number'
            value={newPackage.beersIncluded}
            onChange={(e) =>
              setNewPackage({ ...newPackage, beersIncluded: e.target.value })
            }
          />
        </div>
        <div>
          <TextField
            label='Package Price'
            type='number'
            value={newPackage.packagePrice}
            onChange={(e) =>
              setNewPackage({ ...newPackage, packagePrice: e.target.value })
            }
          />
          <TextField
            label='Stripe Price Id'
            value={newPackage.stripePriceId}
            onChange={(e) =>
              setNewPackage({ ...newPackage, stripePriceId: e.target.value })
            }
          />
          <div className='flex flex-col'>
            <label className='mb-2'>Package Description</label>
            <textarea
              className='border rounded-lg mb-4 p-2'
              rows={4}
              value={newPackage.packageDescription}
              onChange={(e) =>
                setNewPackage({
                  ...newPackage,
                  packageDescription: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
      <Button label='Save' onClick={() => onSaveHandler()} disabled={loading} />
    </ModalContainer>
  );
}

export default NewPackageModal;
