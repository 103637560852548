import React, { useState } from "react";
import Layout from "../../components/layout";
import Button from "../../components/ui/Button";
import EditPackageModal from "./components/EditPackageModal";
import NewPackageModal from "./components/NewPackageModal";
import PackagesTable from "./components/PackagesTable";

function PackagesScreen() {
  const [showNewPackage, setShowNewPackage] = useState(false);
  const [showEditPackage, setShowEditPackage] = useState(false);
  const [packageToEdit, setPackageToEdit] = useState(null);

  const editPackageHandler = (editPackage) => {
    setPackageToEdit(editPackage);
    setShowEditPackage(true);
  };

  return (
    <Layout>
      <div>
        <Button
          label='New Package'
          selected={showNewPackage}
          onClick={() => setShowNewPackage(!showNewPackage)}
        />
      </div>
      <div>
        <PackagesTable editPackageHandler={editPackageHandler} />
      </div>
      {showNewPackage && (
        <NewPackageModal setShowNewPackage={setShowNewPackage} />
      )}
      {showEditPackage && (
        <EditPackageModal
          setShowEditPackage={setShowEditPackage}
          packageToEdit={packageToEdit}
        />
      )}
    </Layout>
  );
}

export default PackagesScreen;
