import React, { useState } from "react";
import Layout from "../../components/layout";
import Button from "../../components/ui/Button";
import AddBeerModal from "./components/AddBeerModal";
import BeersTable from "./components/BeersTable";

function BeersScreen() {
  const [addNewBeer, setAddNewBeer] = useState(false);
  return (
    <Layout>
      <div>
        <Button
          label='New Beer'
          selected={addNewBeer}
          onClick={() => setAddNewBeer(!addNewBeer)}
        />
      </div>
      <div>
        <BeersTable />
      </div>
      {addNewBeer && <AddBeerModal setAddNewBeer={setAddNewBeer} />}
    </Layout>
  );
}

export default BeersScreen;
