import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../components/ui/Button";
import TextField from "../../components/ui/TextField";
import { signInUserEmailAndPassword } from "../../redux/actions/auth.action";

function LoginScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const signInHandler = (e) => {
    e.preventDefault();
    dispatch(signInUserEmailAndPassword(email, password));
  };
  return (
    <div className='flex flex-col min-h-screen justify-center items-center px-4'>
      <h3 className='text-2xl font-semibold mb-8'>Barmans Beer Club - Admin</h3>
      <form className='flex flex-col w-full md:w-4/12'>
        <TextField
          label='Email Address'
          type='text'
          placeholder='Enter email address'
          autoComplete='username'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label='Password'
          type='password'
          placeholder='Enter password'
          autoComplete='current-password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button label='Sign In' onClick={(e) => signInHandler(e)} />
      </form>
    </div>
  );
}

export default LoginScreen;
