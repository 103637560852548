import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { firebaseAuth } from "./firebase";
import {
  markNotAuthenticated,
  signInUserToken,
} from "./redux/actions/auth.action";
import LoadingSpinner from "./components/ui/LoadingSpinner";
import DashboardScreen from "./routes/Dashboard";
import LoginScreen from "./routes/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BeersScreen from "./routes/Beers";
import PackagesScreen from "./routes/Packages";
import SubscriptionsScreen from "./routes/Subscriptions";

export default function App() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.signedIn === null) {
      onAuthStateChanged(firebaseAuth, (user) => {
        if (user) {
          dispatch(signInUserToken(user.accessToken));
        } else {
          dispatch(markNotAuthenticated());
        }
      });
    }
  }, [auth, dispatch]);

  return (
    <BrowserRouter>
      {auth.signedIn === null ? (
        <div className='flex flex-col w-full h-screen justify-center items-center'>
          <LoadingSpinner />
          <p className='mt-8 text-2xl'>Loading details...</p>
        </div>
      ) : auth.signedIn ? (
        <Routes>
          <Route path='/' element={<DashboardScreen />} />
          <Route path='/packages' element={<PackagesScreen />} />
          <Route path='/subscriptions' element={<SubscriptionsScreen />} />
          <Route path='/beers' element={<BeersScreen />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      ) : (
        <Routes>
          <Route path='/' element={<LoginScreen />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      )}
      <ToastContainer autoClose={2000} position='bottom-left' />
    </BrowserRouter>
  );
}
