import React from "react";
import ActiveNavButton from "../../ui/ActiveNavButton";

function SideBar() {
  return (
    <div className='hidden md:flex flex-col  md:w-2/12 p-2 space-y-4'>
      <ActiveNavButton to='/' label='Dashboard' />
      <ActiveNavButton to='/beers' label='Beers' />
      <ActiveNavButton to='/packages' label='Packages' />
      <ActiveNavButton to='/subscriptions' label='Subscriptions' />
    </div>
  );
}

export default SideBar;
