import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import EditIcon from "../../../components/icons/EditIcon";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { firebaseAuth } from "../../../firebase";

function BeersTable() {
  const [loading, setLoading] = useState(false);
  const [beers, setBeers] = useState(null);

  useEffect(() => {
    if (beers === null) {
      setLoading(true);
      onAuthStateChanged(firebaseAuth, async (user) => {
        if (user) {
          const userToken = user.accessToken;
          try {
            const { data } = await axios.get(
              `${process.env.REACT_APP_BACKEND}/admin/beer`,
              {
                headers: {
                  Authorization: "Bearer " + userToken,
                },
              }
            );
            setBeers(data);
            setLoading(false);
          } catch (error) {
            console.log(error);
            toast.error("Error retrieving beers");
          }
        }
      });
    }
  }, [beers]);

  return (
    <div className='flex flex-col mt-8'>
      <table className='table-auto border w-full mx-auto'>
        <thead>
          <tr className='bg-slate-700 text-white'>
            <th className='text-left border px-2 py-1'>Brewer</th>
            <th className='border px-2 py-1'>Beer</th>
            <th className='border px-2 py-1'>ABV</th>
            <th className='border px-2 py-1'>Cost</th>
            <th className='border px-2 py-1'>Stock</th>
            <th className='border px-2 py-1'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {beers &&
            beers.map((row, index) => (
              <tr
                key={row.id}
                className={index % 2 ? "bg-slate-100" : "bg-slate-300"}
              >
                <td className='text-left border px-2 py-1'>{row.brewery}</td>
                <td className='text-center border px-2 py-1'>{row.beerName}</td>
                <td className='text-center border px-2 py-1'>{row.beerAbv}%</td>
                <td className='text-center border px-2 py-1'>
                  £{row.beerCost / 100}
                </td>
                <td className='text-center border px-2 py-1'>
                  {row.beerStock}
                </td>
                <td className='text-center border px-2 py-1 space-x-4'>
                  <button>
                    <EditIcon />
                  </button>
                  <button>
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {loading && (
        <div className='flex flex-col w-full justify-center items-center mt-4'>
          <LoadingSpinner />
          <p className='mt-2'>Loading Beers...</p>
        </div>
      )}
    </div>
  );
}

export default BeersTable;
