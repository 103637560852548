import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import EditIcon from "../../../components/icons/EditIcon";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { firebaseAuth } from "../../../firebase";

function PackagesTable({ editPackageHandler }) {
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState(null);

  const deletePackageHandler = (deletePackage) => {
    console.log(deletePackage);
  };

  useEffect(() => {
    if (packages === null) {
      setLoading(true);
      onAuthStateChanged(firebaseAuth, async (user) => {
        if (user) {
          const userToken = user.accessToken;
          try {
            const { data } = await axios.get(
              `${process.env.REACT_APP_BACKEND}/admin/package`,
              {
                headers: {
                  Authorization: "Bearer " + userToken,
                },
              }
            );
            setPackages(data);
            setLoading(false);
          } catch (error) {
            console.log(error);
            toast.error("Error retrieving packages");
          }
        }
      });
    }
  }, [packages]);
  return (
    <div className='flex flex-col mt-8'>
      <table className='table-auto border w-full mx-auto'>
        <thead>
          <tr className='bg-slate-700 text-white'>
            <th className='text-left border px-2 py-1'>Name</th>
            <th className='border px-2 py-1'>Price</th>
            <th className='border px-2 py-1'>Description</th>
            <th className='border px-2 py-1'>Frequency</th>
            <th className='border px-2 py-1'>Beer Qty</th>
            <th className='border px-2 py-1'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {packages &&
            packages.map((row, index) => (
              <tr
                key={row.id}
                className={index % 2 ? "bg-slate-100" : "bg-slate-300"}
              >
                <td className='text-left border px-2 py-1'>
                  {row.packageName}
                </td>
                <td className='text-center border px-2 py-1'>
                  £{row.packagePrice / 100}
                </td>
                <td className='text-left border px-2 py-1'>
                  {row.packageDescription}
                </td>
                <td className='text-center border px-2 py-1'>
                  {row.packageFrequency === "2Weeks"
                    ? "Fortnightly"
                    : row.packageFrequency === "1Month"
                    ? "Monthly"
                    : row.packageFrequency}
                </td>
                <td className='text-center border px-2 py-1'>
                  {row.beersIncluded}
                </td>
                <td className='text-center border px-2 py-1 space-x-4'>
                  <button onClick={() => editPackageHandler(row)}>
                    <EditIcon />
                  </button>
                  <button onClick={() => deletePackageHandler(row)}>
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {loading && (
        <div className='flex flex-col w-full justify-center items-center mt-4'>
          <LoadingSpinner />
          <p>Loading Packages...</p>
        </div>
      )}
    </div>
  );
}

export default PackagesTable;
