import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/ui/Button";
import ModalContainer from "../../../components/ui/ModalContainer";
import SelectField from "../../../components/ui/SelectField";
import TextField from "../../../components/ui/TextField";
import { firebaseAuth } from "../../../firebase";

function EditPackageModal({ setShowEditPackage, packageToEdit }) {
  const [loading, setLoading] = useState(false);
  const [editSet, setEditSet] = useState(false);
  const [editPackage, setEditPackage] = useState({
    id: "",
    packageName: "",
    packageFrequency: "",
    beersIncluded: 4,
    packagePrice: 0,
    stripePriceId: "",
    packageDescription: "",
  });

  const onCloseHandler = () => {
    setShowEditPackage(false);
  };

  const onUpdateHandler = () => {
    setLoading(true);
    onAuthStateChanged(firebaseAuth, async (user) => {
      if (user) {
        const userToken = user.accessToken;

        try {
          const { data } = await axios.put(
            `${process.env.REACT_APP_BACKEND}/admin/package`,
            {
              editPackage,
            },
            {
              headers: {
                Authorization: "Bearer " + userToken,
              },
            }
          );
          if (data.id) {
            toast.success("Package Updated");
            setShowEditPackage(false);
          } else {
            toast.error("Error updating package");
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          toast.error("Error updating package");
          setLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    if (!editSet) {
      setEditPackage({
        id: packageToEdit.id,
        packageName: packageToEdit.packageName,
        packageFrequency: packageToEdit.packageFrequency,
        beersIncluded: packageToEdit.beersIncluded,
        packagePrice: packageToEdit.packagePrice / 100,
        stripePriceId: packageToEdit.stripePriceId,
        packageDescription: packageToEdit.packageDescription,
      });
      setEditSet(true);
    }
  }, [editSet, packageToEdit]);

  const frequencyOptions = [
    {
      label: "Fortnightly",
      value: "2Weeks",
    },
    {
      label: "Monthly",
      value: "1Month",
    },
  ];
  return (
    <ModalContainer title='Edit Package' onClose={() => onCloseHandler()}>
      <div className='flex space-x-4'>
        <div>
          <TextField
            label='Package Name'
            value={editPackage.packageName}
            onChange={(e) =>
              setEditPackage({ ...editPackage, packageName: e.target.value })
            }
          />
          <SelectField
            label='Delivery Frequency'
            options={frequencyOptions}
            value={editPackage.packageFrequency}
            onChange={(e) =>
              setEditPackage({
                ...editPackage,
                packageFrequency: e.target.value,
              })
            }
          />

          <TextField
            label='Beers Included'
            type='number'
            value={editPackage.beersIncluded}
            onChange={(e) =>
              setEditPackage({ ...editPackage, beersIncluded: e.target.value })
            }
          />
        </div>
        <div>
          <TextField
            label='Package Price'
            type='number'
            value={editPackage.packagePrice}
            onChange={(e) =>
              setEditPackage({ ...editPackage, packagePrice: e.target.value })
            }
          />
          <TextField
            label='Stripe Price Id'
            value={editPackage.stripePriceId}
            onChange={(e) =>
              setEditPackage({ ...editPackage, stripePriceId: e.target.value })
            }
          />
          <div className='flex flex-col'>
            <label className='mb-2'>Package Description</label>
            <textarea
              className='border rounded-lg mb-4 p-2'
              rows={4}
              value={editPackage.packageDescription}
              onChange={(e) =>
                setEditPackage({
                  ...editPackage,
                  packageDescription: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
      <Button
        label='Update'
        onClick={() => onUpdateHandler()}
        disabled={loading}
      />
    </ModalContainer>
  );
}

export default EditPackageModal;
