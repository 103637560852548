import * as t from "../constants/auth.constant";
import { toast } from "react-toastify";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { firebaseAuth } from "../../firebase";
import axios from "axios";
import { mapAuthCodeToMessage } from "../../utils/firebaseAuthErrorHandling";

export const signInUserEmailAndPassword =
  (email, password) => async (dispatch) => {
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .then(async (userCredential) => {
        var userToken = userCredential.user.accessToken;
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND}/admin`,
            {
              headers: {
                Authorization: "Bearer " + userToken,
              },
            }
          );
          dispatch({
            type: t.SIGN_IN_USER,
            payload: data,
          });
        } catch (error) {
          console.log(error);
        }
      })
      .catch((error) => {
        const message = mapAuthCodeToMessage(error.code);
        console.log(message === "" ? error.code : message);
        toast.error(message === "" ? error.code : message);
      });
  };

export const signInUserToken = (token) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/admin`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: t.SIGN_IN_USER,
      payload: data,
    });
  } catch (error) {
    console.log(error.response.status);
    if (error.response.status === 401) {
      toast.error("Not Authorised");
    }
  }
};

export const signOutUser = () => async (dispatch) => {
  signOut(firebaseAuth)
    .then(() => {
      dispatch({
        type: t.SIGN_OUT_USER,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const markNotAuthenticated = () => (dispatch) => {
  dispatch({
    type: t.NOT_AUTHENTICATED,
  });
};
